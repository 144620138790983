import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteHeader = _resolveComponent("SiteHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SiteFooter = _resolveComponent("SiteFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SiteHeader),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_SiteFooter)
  ]))
}