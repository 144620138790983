import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "progress-bar" }
const _hoisted_2 = {
  class: "progress-bar-fill",
  ref: "progressBarFill"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createElementVNode("div", {
      class: _normalizeClass(["text", {'add-padding': _ctx.hidePercent}])
    }, [
      _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.numer), 1),
      _createTextVNode("/"),
      _createElementVNode("span", null, _toDisplayString(_ctx.denom), 1),
      _createTextVNode(),
      (!_ctx.hidePercent)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(" + _toDisplayString(_ctx.percentDone) + "%)", 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}