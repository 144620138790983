import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "publications" }
const _hoisted_2 = { class: "section-body" }
const _hoisted_3 = { class: "publication-type-header" }
const _hoisted_4 = { class: "publication-type-sub-header" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_JournalPublication = _resolveComponent("JournalPublication")!
  const _component_ThesisPublication = _resolveComponent("ThesisPublication")!
  const _component_PresentationPublication = _resolveComponent("PresentationPublication")!
  const _component_UnpublishedPublication = _resolveComponent("UnpublishedPublication")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_SectionHeader, {
      title: "Publications",
      icon: "paragraph",
      subtext: "This page contains links to publications and presentations to which I've contributed."
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewPublications, (vPub) => {
        return (_openBlock(), _createElementBlock("div", {
          key: vPub.type
        }, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(vPub.header), 1),
          _createElementVNode("h5", _hoisted_4, _toDisplayString(vPub.subHeader), 1),
          (vPub.type === _ctx.journal)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vPub.items, (publication, idx) => {
                  return (_openBlock(), _createBlock(_component_JournalPublication, {
                    key: publication.title,
                    journal: publication,
                    publicationNumber: vPub.items.length - idx
                  }, null, 8, ["journal", "publicationNumber"]))
                }), 128))
              ]))
            : (vPub.type === _ctx.thesis)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vPub.items, (publication, idx) => {
                    return (_openBlock(), _createBlock(_component_ThesisPublication, {
                      key: publication.title,
                      thesis: publication,
                      publicationNumber: vPub.items.length - idx
                    }, null, 8, ["thesis", "publicationNumber"]))
                  }), 128))
                ]))
              : (vPub.type === _ctx.presentation)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vPub.items, (publication, idx) => {
                      return (_openBlock(), _createBlock(_component_PresentationPublication, {
                        key: publication.title,
                        presentation: publication,
                        publicationNumber: vPub.items.length - idx
                      }, null, 8, ["presentation", "publicationNumber"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
          (vPub.type === _ctx.unpublished)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vPub.items, (publication, idx) => {
                  return (_openBlock(), _createBlock(_component_UnpublishedPublication, {
                    key: publication.title,
                    unpublishedPublication: publication,
                    publicationNumber: vPub.items.length - idx
                  }, null, 8, ["unpublishedPublication", "publicationNumber"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}