import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "full-navbar" }
const _hoisted_2 = { class: "nav-items" }
const _hoisted_3 = {
  class: "active-route-highlight",
  ref: "activeRouteHighlight"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_FullNavItem = _resolveComponent("FullNavItem")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_BackButton),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, null, 512),
      _createVNode(_component_FullNavItem, {
        ref: "aboutMeNav",
        route: "/about-me",
        text: "About Me",
        onClicked: _ctx.updateHighlight
      }, null, 8, ["onClicked"]),
      _createVNode(_component_FullNavItem, {
        ref: "publicationsNav",
        route: "/publications",
        text: "Publications",
        onClicked: _ctx.updateHighlight
      }, null, 8, ["onClicked"]),
      _createVNode(_component_FullNavItem, {
        ref: "bookshelfNav",
        route: "/bookshelf",
        text: "Bookshelf",
        onClicked: _ctx.updateHighlight
      }, null, 8, ["onClicked"]),
      _createVNode(_component_FullNavItem, {
        ref: "articlesNav",
        route: "/articles",
        text: "Articles",
        onClicked: _ctx.updateHighlight
      }, null, 8, ["onClicked"]),
      _createVNode(_component_FullNavItem, {
        ref: "runningNav",
        route: "/running",
        text: "Running",
        onClicked: _ctx.updateHighlight
      }, null, 8, ["onClicked"])
    ])
  ]))
}