import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "new-nav-bar header-dark" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "condensed-navbar-back-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullNavBar = _resolveComponent("FullNavBar")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_CondensedNavBar = _resolveComponent("CondensedNavBar")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FullNavBar),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BackButton)
      ]),
      _createElementVNode("div", {
        class: "condensed-navbar-icon",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMobileNavBar && _ctx.toggleMobileNavBar(...args)))
      }, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'bars'],
          class: "nav-icon"
        })
      ])
    ]),
    _createVNode(_component_CondensedNavBar, {
      mobileNavbarVisible: _ctx.mobileNavbarVisible,
      onCloseMobileNavBar: _ctx.closeMobileNavBar
    }, null, 8, ["mobileNavbarVisible", "onCloseMobileNavBar"])
  ]))
}