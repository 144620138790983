import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "selected-article" }
const _hoisted_2 = { class: "meta-container" }
const _hoisted_3 = { class: "dates" }
const _hoisted_4 = { class: "tags" }
const _hoisted_5 = { class: "section-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_GitHubMarkdown = _resolveComponent("GitHubMarkdown")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.selectedArticle.title,
      icon: "",
      subtext: _ctx.selectedArticle.description
    }, null, 8, ["title", "subtext"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, " Created " + _toDisplayString(_ctx.formatDate(_ctx.selectedArticle.createdAt)) + " • Updated " + _toDisplayString(_ctx.formatDate(_ctx.selectedArticle.updatedAt)), 1),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedArticle.tags, (tag) => {
          return (_openBlock(), _createBlock(_component_Tag, {
            key: tag,
            tag: tag
          }, null, 8, ["tag"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_GitHubMarkdown, {
        content: _ctx.selectedArticle.body
      }, null, 8, ["content"])
    ])
  ]))
}