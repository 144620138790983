import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "work-education" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerticalTimeline = _resolveComponent("VerticalTimeline")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_VerticalTimeline, {
      id: "work",
      type: "work",
      title: "Work",
      icon: "code",
      timelineEntities: $data.workEntities,
      showLimit: 4
    }, null, 8, ["timelineEntities"]),
    true
      ? (_openBlock(), _createBlock(_component_VerticalTimeline, {
          key: 0,
          id: "education",
          type: "education",
          title: "Education",
          icon: "graduation-cap",
          timelineEntities: $data.educationEntities
        }, null, 8, ["timelineEntities"]))
      : _createCommentVNode("", true)
  ]))
}