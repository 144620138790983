import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "image-carousel" }
const _hoisted_2 = { class: "images" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "caption-text" }
const _hoisted_5 = { class: "dots" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (img) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "image-slide fade",
          key: img.src
        }, [
          _createElementVNode("img", {
            src: img.src
          }, null, 8, _hoisted_3)
        ]))
      }), 128)),
      _createElementVNode("a", {
        class: "prev",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.plusSlides(-1)))
      }, "❮"),
      _createElementVNode("a", {
        class: "next",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.plusSlides(1)))
      }, "❯")
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.selectedImgCaptionText), 1),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, i) => {
        return (_openBlock(), _createElementBlock("span", {
          key: image.src,
          class: "dot",
          onClick: ($event: any) => (_ctx.currentSlide(i))
        }, null, 8, _hoisted_6))
      }), 128))
    ])
  ]))
}