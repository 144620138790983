import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tech-used-icon",
    style: _normalizeStyle(_ctx.tagStyle)
  }, [
    _createElementVNode("img", {
      src: _ctx.imageSource(_ctx.tech._imagePath)
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("b", null, _toDisplayString(_ctx.tech._title), 1)
    ])
  ], 4))
}