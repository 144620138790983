import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "body" }
const _hoisted_3 = { class: "dates" }
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { class: "tags" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!

  return (_openBlock(), _createElementBlock("div", {
    class: "article-card",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cardClicked && _ctx.cardClicked(...args)))
  }, [
    _createElementVNode("header", null, [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.article.title), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, " Created " + _toDisplayString(_ctx.formatDate(_ctx.article.createdAt)) + " • Updated " + _toDisplayString(_ctx.formatDate(_ctx.article.updatedAt)), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(_ctx.article.description), 1)
      ])
    ]),
    _createElementVNode("footer", null, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.article.tags, (tag) => {
          return (_openBlock(), _createBlock(_component_Tag, {
            key: tag,
            tag: tag
          }, null, 8, ["tag"]))
        }), 128))
      ])
    ])
  ]))
}