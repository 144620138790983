import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "races" }
const _hoisted_2 = { class: "section-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_RunningCard = _resolveComponent("RunningCard")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_SectionHeader, {
      title: "Running",
      icon: "running",
      subtext: "I got into running while the pandemic had the gyms closed. Now I'd like to make a habit of running a different marathon every few years, with some other races every now and then. I ran my first official marathon in the Spring of 2022, which is listed below. Hopefully I'll add more to this list in the coming years."
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedRaces, (race) => {
        return (_openBlock(), _createBlock(_component_RunningCard, {
          key: race.name,
          race: race
        }, null, 8, ["race"]))
      }), 128))
    ])
  ]))
}