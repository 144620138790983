import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "about-me" }
const _hoisted_2 = { class: "section-body" }
const _hoisted_3 = { class: "image-container" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_SectionHeader, {
      title: "About Me",
      icon: "user"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SkeletonLoader, {
          imagePath: _ctx.aboutMe.imageFileName,
          imageAlt: "Ben Rombaut",
          isCircle: true
        }, null, 8, ["imagePath"])
      ]),
      _createElementVNode("div", {
        class: "text-container",
        innerHTML: _ctx.aboutMe.description
      }, null, 8, _hoisted_4)
    ])
  ]))
}