import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "thesis-publication" }
const _hoisted_2 = { class: "publication-index" }
const _hoisted_3 = { class: "publication-info" }
const _hoisted_4 = { class: "publication-info-entity title" }
const _hoisted_5 = { class: "publication-info-entity authors" }
const _hoisted_6 = { class: "underline" }
const _hoisted_7 = { class: "publication-info-entity venue" }
const _hoisted_8 = { class: "publication-info-entity links" }
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, "[" + _toDisplayString(_ctx.thesis.type) + _toDisplayString(_ctx.publicationNumber) + "]", 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, [
          _createElementVNode("b", null, _toDisplayString(_ctx.thesis.title), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.thesis.authors), 1),
        _createTextVNode(" - "),
        _createElementVNode("span", null, _toDisplayString(_ctx.thesis.thesisType), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("i", null, _toDisplayString(_ctx.thesis.venue), 1),
        _createTextVNode(", " + _toDisplayString(_ctx.thesis.month) + " " + _toDisplayString(_ctx.thesis.year), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.thesis.links, (link) => {
          return (_openBlock(), _createElementBlock("a", {
            key: link.url,
            href: link.url,
            target: "_blank"
          }, [
            _createElementVNode("span", null, "[" + _toDisplayString(link.type) + "]", 1)
          ], 8, _hoisted_9))
        }), 128))
      ])
    ])
  ]))
}