import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { id: "selected-software" }
const _hoisted_2 = { class: "meta-container" }
const _hoisted_3 = { class: "tech-used" }
const _hoisted_4 = { class: "external-profiles" }
const _hoisted_5 = { class: "dates" }
const _hoisted_6 = { class: "section-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_TechUsedIcon = _resolveComponent("TechUsedIcon")!
  const _component_ExternalRepoIcon = _resolveComponent("ExternalRepoIcon")!
  const _component_GitHubMarkdown = _resolveComponent("GitHubMarkdown")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.selectedSoftware.title,
      icon: "",
      subtext: _ctx.selectedSoftware.description
    }, null, 8, ["title", "subtext"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedSoftware.techUsed, (tech) => {
          return (_openBlock(), _createBlock(_component_TechUsedIcon, {
            key: tech._id,
            tech: tech
          }, null, 8, ["tech"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedSoftware.externalRepos, (repo) => {
          return (_openBlock(), _createBlock(_component_ExternalRepoIcon, {
            key: repo._id,
            externalRepo: repo,
            verbose: true
          }, null, 8, ["externalRepo"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, " Created " + _toDisplayString(_ctx.formatDate(_ctx.selectedSoftware.createdAt)) + " • Updated " + _toDisplayString(_ctx.formatDate(_ctx.selectedSoftware.updatedAt)), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_GitHubMarkdown, {
        content: _ctx.selectedSoftware.body
      }, null, 8, ["content"])
    ])
  ]))
}