import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "software" }
const _hoisted_2 = { class: "section-body" }
const _hoisted_3 = { class: "software-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_SoftwareCard = _resolveComponent("SoftwareCard")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_SectionHeader, {
      title: "Software",
      icon: "code",
      subtext: "Software projects I've been working on."
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.softwareProjectsToDisplay, (software) => {
          return (_openBlock(), _createBlock(_component_SoftwareCard, {
            key: software.id,
            software: software,
            onClicked: _ctx.softwareClicked
          }, null, 8, ["software", "onClicked"]))
        }), 128))
      ])
    ])
  ]))
}