import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "articles" }
const _hoisted_2 = { class: "section-body" }
const _hoisted_3 = {
  key: 0,
  class: "articles-table-container"
}
const _hoisted_4 = {
  key: 1,
  class: "articles-cards-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_ArticlesTable = _resolveComponent("ArticlesTable")!
  const _component_ArticleCard = _resolveComponent("ArticleCard")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_SectionHeader, {
      title: "Articles",
      icon: "pen-square",
      subtext: "A collection of how-to guides and notes I've written on different topics, mostly so that I can use them as references later."
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.tableView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ArticlesTable, {
              articles: _ctx.articlesToDisplay,
              onClicked: _ctx.articleClicked
            }, null, 8, ["articles", "onClicked"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articlesToDisplay, (article) => {
              return (_openBlock(), _createBlock(_component_ArticleCard, {
                key: article.id,
                article: article,
                onClicked: _ctx.articleClicked
              }, null, 8, ["article", "onClicked"]))
            }), 128))
          ]))
    ])
  ]))
}