import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "unpublished-publication" }
const _hoisted_2 = { class: "publication-index" }
const _hoisted_3 = { class: "publication-info" }
const _hoisted_4 = { class: "publication-info-entity title" }
const _hoisted_5 = { class: "publication-info-entity authors" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "links" }
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, "[" + _toDisplayString(_ctx.unpublishedPublication.type) + _toDisplayString(_ctx.publicationNumber) + "]", 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, [
          _createElementVNode("b", null, _toDisplayString(_ctx.unpublishedPublication.title), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unpublishedPublication.authors, (a, idx) => {
          return (_openBlock(), _createElementBlock("span", { key: a }, [
            _createElementVNode("span", {
              class: _normalizeClass({underline: _ctx.authorIsBen(a)})
            }, _toDisplayString(a), 3),
            (idx < _ctx.unpublishedPublication.authors.length - 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, ", "))
              : (_openBlock(), _createElementBlock("span", _hoisted_7, "."))
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unpublishedPublication.links, (link) => {
          return (_openBlock(), _createElementBlock("a", {
            key: link.url,
            href: link.url,
            target: "_blank"
          }, [
            _createElementVNode("span", null, "[" + _toDisplayString(link.type) + "]", 1)
          ], 8, _hoisted_9))
        }), 128))
      ])
    ])
  ]))
}