import { vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "skeleton" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass([_ctx.classObject, "skeleton__loader"])
    }, null, 2), [
      [_vShow, _ctx.imageLoading]
    ]),
    _withDirectives(_createElementVNode("img", {
      src: _ctx.imageSource(),
      alt: _ctx.imageAlt,
      class: _normalizeClass({'circle': _ctx.isCircle})
    }, null, 10, _hoisted_2), [
      [_vShow, !_ctx.imageLoading]
    ])
  ]))
}