import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "external-profile-link" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "link",
      href: _ctx.profile.url(),
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.handleExternalProfileClicked()), ["stop","prevent"]))
    }, [
      _createVNode(_component_font_awesome_icon, {
        class: "icon",
        icon: _ctx.profile.icon()
      }, null, 8, ["icon"])
    ], 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.profile.id()), 1)
  ]))
}